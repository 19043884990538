import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Разработка крутых приложений | Кодовое слово  
			</title>
			<meta name={"description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:title"} content={"Наши проекты | Кодовое слово  "} />
			<meta property={"og:description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/favicon.ico?v=2021-08-10T08:08:05.993Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60feb3ae63f5070017af70c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"619b78f310dffeaeba606725"} />
			<style place={"endOfBody"} rawKey={"619b7917f5e4ef1492682e06"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"620d0b13860a3cd523791a26"}>
				{"<!-- Yandex.Metrika counter -->\n<script type=\"text/javascript\" >\n   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(87531028, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });\n</script>\n<noscript><div><img src=\"https://mc.yandex.ru/watch/87531028\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div></noscript>\n<!-- /Yandex.Metrika counter -->"}
			</script>
		</RawHtml>
	</Theme>;
});